
// Libraries
import qs from 'qs'

// Services
import AuthService from './auth'

// Get the API URL from dev/prod environment variables
const apiURL = process.env.API_URL

// Runtime API service
export default class ApiService {
	// Send request to API
	async fetch(endpoint, options, method, files = false) {
		// Stop function from running if API_URL is not set
		if(!apiURL) return

		// Make the request
		const request = await fetch(`${apiURL}/${endpoint}`, {
			// Use method parameter for request type
			method: method,
			headers: {
				// Expect JSON
				'Accept': 'application/json',
				...(!files && { 'Content-Type': 'application/json;charset=UTF-8' }),
				// Follow redirects
				'Redirect': 'follow',
				// Send access token if available
				...(this.getAccessToken() && { 'Authorization': `Bearer ${this.getAccessToken()}` }),
			},
			// Spread options parameter into request
			...options,
		})

		// Await the request and convert JSON to an object
		const response = await request,
			json = await response?.json()

		// Check if response was unsuccessful
		if(!response?.ok) {
			// Check if authenticate endpoint was requested with invalid token
			if(response?.status === 401) {
				const authService = new AuthService()

				// Logout if token doesn't work
				return authService.logOut()
			}

			// Return some info about the error
			return {
				success: false,
				// Add message for reason of failure
				message: json?.errors ? 'Validation failed' : response?.statusText || 'Could not process the request',
				// Return validation errors if any are present
				...(json?.errors && { errors: json.errors }),
			}
		}

		// Return response data as JSON
		return {
			success: true,
			data: json
		}
	}

	// Send request to API
	async fetchBlob(endpoint, options, method) {
		// Stop function from running if API_URL is not set
		if(!apiURL) return

		// Make the request
		const request = await fetch(`${apiURL}/${endpoint}`, {
			// Use method parameter for request type
			method: method,
			headers: {
				// Follow redirects
				'Redirect': 'follow',
				// Send access token if available
				...(this.getAccessToken() && { 'Authorization': `Bearer ${this.getAccessToken()}` }),
			},
			// Spread options parameter into request
			...options,
		})

		// Await the request and convert JSON to an object
		const response = await request,
			blob = await response?.blob()

		// Check if response was unsuccessful
		if(!response?.ok) {
			// Return some info about the error
			return {
				success: false,
				// Add message for reason of failure
				message: 'Could not process the request'
			}
		}

		// Return response data as JSON
		return {
			success: true,
			data: blob
		}
	}

	// Send GET request to API
	async get(endpoint, options, queryParams = {}) {
		// Use the fetch function
		const queryString = new URLSearchParams(qs.stringify(queryParams))
		return await this.fetch(endpoint + (queryString.size > 0 ? '?' + queryString : ''), options, 'GET')
	}

	// Send GET request to API
	async download(endpoint, options, queryParams = {}) {
		// Use the fetch function
		const queryString = new URLSearchParams(qs.stringify(queryParams))
		return await this.fetchBlob(endpoint + (queryString.size > 0 ? '?' + queryString : ''), options, 'GET')
	}

	// Send POST request to API
	async post(endpoint, options, files = false) {
		// Use the fetch function
		return await this.fetch(endpoint, options, 'POST', files)
	}

	// Send PUT request to API
	async put(endpoint, options, files = false) {
		let method = 'PUT'

		if (files) {
			method = 'POST'
			options.body.set('_method', 'PUT')
		}

		// Use the fetch function
		return await this.fetch(endpoint, options, method, files)
	}

	// Send DELETE request to API
	async delete(endpoint, options) {
		// Use the fetch function
		return await this.fetch(endpoint, options, 'DELETE')
	}

	// Retrieve access token from local storage if user is logged in
	getAccessToken() {
		return localStorage.getItem('accessToken')
	}
}
