
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */


// Styles
import * as React from 'react';
import './src/scss/style.scss'

// Context
import { BasketContextProvider } from './src/contexts/basket'
import { NotificationContextProvider } from './src/contexts/notification'

export const wrapRootElement = ({element}) => (
	<NotificationContextProvider>
		<BasketContextProvider>
			{element}
		</BasketContextProvider>
	</NotificationContextProvider>
)

export const onClientEntry = () => {
	if (process.env.GATSBY_MAINTENANCE_MODE === 'true' && typeof window !== 'undefined') {
		if (!window.location.pathname.startsWith('/maintenance') && !window.location.pathname.startsWith('/admin')) {
			window.location = '/maintenance/'
		}
	}
}

export const onRouteUpdate = () => {
	if (process.env.GATSBY_MAINTENANCE_MODE === 'true' && typeof window !== 'undefined') {
		if (!window.location.pathname.startsWith('/maintenance') && !window.location.pathname.startsWith('/admin')) {
			window.location = '/maintenance/'
		}
	}
}
