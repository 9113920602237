
// Libraries
import { navigate } from 'gatsby'

// Services
import ApiService from './api'

export default class AuthService {
	async login(email, password, remember_me = false, admin_check = false) {
		const apiService = new ApiService()

		const response = await apiService.post('login', {
			body: JSON.stringify({
				email: email,
				password: password,
			}),
		})

		if(response?.data?.plainTextToken) {
			const expiresHours = remember_me ? 12 : 2
			const expires = Date.now() + expiresHours * 3600 * 1000
			localStorage.setItem('loggedIn', response?.data?.accessToken.tokenable_id)
			localStorage.setItem('loggedInExpires', expires)
			localStorage.setItem('loggedInExpiresHours', expiresHours)
			localStorage.setItem('accessToken', response?.data?.plainTextToken)

			// To-Do: Bundle the user role into the login endpoint response
			// Then we only need one request
			const apiService = new ApiService()
			const user = await apiService.get(`users/${localStorage.getItem('loggedIn')}`)
			const roleIds = user.data.roles.map(role => role.id)
			localStorage.setItem('roles', roleIds)
			localStorage.setItem('firstName', user.data.first_name)

			if (admin_check && !await this.isAdmin()) {
				// not an admin user
				return {
					success: false,
					message: 'Invalid email/password combination'
				}
			}

			// Return response data as JSON
			return {
				success: true,
				data: await response?.data
			}
		}
		else {
			// Return some info about the error
			return {
				success: false,
				errors: response.errors
			}
		}
	}

	logOut(redirectPath = '/') {
		localStorage.removeItem('loggedIn')
		localStorage.removeItem('loggedInExpires')
		localStorage.removeItem('loggedInExpiresHours')
		localStorage.removeItem('accessToken')
		localStorage.removeItem('roles')
		localStorage.removeItem('firstName')

		return navigate(redirectPath)
	}

	async isLoggedIn() {
		const isBrowser = typeof window !== "undefined"

		return !!isBrowser
			&& localStorage.getItem('loggedIn')
			&& localStorage.getItem('accessToken')
			&& localStorage.getItem('roles')
			&& localStorage.getItem('loggedInExpires') > Date.now()
	}

	async isAdmin(apiRequest = false) {
		if(!await this.isLoggedIn()) {
			return false;
		}

		let roleIds
		
		if(apiRequest) {
			const apiService = new ApiService()
			const user = await apiService.get(`users/${localStorage.getItem('loggedIn')}`)
			roleIds = user.data.roles.map(role => role.id)
			return roleIds.includes(2)
		}

		roleIds = localStorage.getItem('roles')

		return roleIds.includes(2)
	}

	async middleware() {
		if(!await this.isLoggedIn()) {
			return this.logOut()
		}
		else {
			const expires = Date.now() + localStorage.getItem('loggedInExpiresHours') * 3600 * 1000
			localStorage.setItem('loggedInExpires', expires)
			return true
		}
	}

	async forgottenPassword(email) {
		const apiService = new ApiService()

		// Request password reset
		const response = await apiService.post('password/request', {
			body: JSON.stringify({
				email: email
			}),
		})

		// Check no errors with request
		// Note: endpoint always returns successful to prevent disclosing existing user accounts
		if(!response?.success) {
			return {
				success: false,
				errors: {
					email: 'Failed to process',
				}
			}
		}

		// Navigate to success page
		return navigate('/forgotten-password-success')
	}

	async register(process, step = 1, inputValues, skipRedirectOnSuccess = false) {
		// Initialise new instance of API service
		const apiService = new ApiService()

		// Create dynamic variables
		let endpoint = `registration/step${step}`,
		response,
		nhs = true

		if(process === 'nhs-carer') {
			nhs = true
		}
		else if(process === 'non-nhs-carer') {
			nhs = false
		}

		const formData = {...inputValues};
		Object.keys(formData).forEach(key => {
			if (['id', 'trust_id', 'employment_status_id', 'organisation_id'].includes(key)) {
				formData[key] = formData[key]['value'];
			}
		})

		// Set initial request body
		let body = JSON.stringify({
			is_nhs: !!nhs,
			...this.getRegistrationToken() && { registration_token: this.getRegistrationToken() },
			...formData
		})

		// Validate organisation ID
		if(step === 0) {
			endpoint = 'registration/organisation/validate'
			body = JSON.stringify({
				id : formData?.id,
			})
		}
		else if(step === 2) { // Use form data to handle file upload
			body = new FormData()

			body.append('is_nhs', nhs ? 'true' : 'false')
			body.append('image', formData.image)
			body.append('registration_token', this.getRegistrationToken())
		}
		else if(step === 3) {
			endpoint = 'registration/trust/validate'
		}
		else if(step === 4) {
			endpoint = 'registration/step3'
		}
		else if(step === 5) {
			endpoint = 'organisations'
		}

		response = await apiService.post(endpoint, {
			body: body,
		}, step === 2)

		// Set registration token in local storage if available
		if(response?.data?.registration_token) {
			localStorage.setItem('registrationToken', response?.data?.registration_token)
		}

		// Navigate to success page if registration is complete
		if(response?.success && !skipRedirectOnSuccess) {
			setTimeout(() => {
				if(process === 'organisation' && response?.data?.created_at) {
					return navigate('/register/organisation/success')
				}

				if(response?.data?.registration_complete) {
					return navigate(`/register/carer/success/${response?.data?.registration_token}`)	
				}
			}, 2000);
		}

		// Return request response
		return response
	}

	// Retrieve registration token from local storage
	getRegistrationToken() {
		return localStorage.getItem('registrationToken')
	}
}
