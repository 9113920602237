import * as React from 'react'
import {createContext} from "preact";
import ApiService from "../services/api";
import AuthService from '../services/auth'

export const BasketContext = createContext({
	items: [],
	subtotal: 0,
	total: 0,
	itemCount: 0
});

export class BasketContextProvider extends React.Component {
	state = {
		items: [],
		subtotal: 0,
		total: 0,
		itemCount: 0,
		totalFaceValue: 0,
		paymentIntentId: null,
		isLoggedIn: false,
	}

	api = new ApiService();
	auth = new AuthService()

	update = (data) => {
		this.setState({
			items: data.items,
			subtotal: data.subtotal,
			total: data.total,
			itemCount: data.itemCount,
			totalFaceValue: data.totalFaceValue,
			paymentIntentId: data.payment_intent_id
		})
	}

	refresh = () => {
		this.auth.isLoggedIn().then(isLoggedIn => {
			if(!isLoggedIn) return

			this.api.get('basket').then((response) => {
				if (response.success) {
					this.update(response.data);
				}
			})
		})
	}

	updateItem = (item, changes) => {
		this.api.put('basket', {
			body: JSON.stringify({...item, ...changes})
		}).then(() => {
			this.refresh();
		})
	}

	removeItem = (item) => {
		this.updateItem(item, {
			quantity: item.quantity * -1
		});
	}

	hasItem = (itemId) => {
		for (let i = 0; i < this.state.items.length; i++) {
			if (this.state.items[i].item_id === itemId) {
				return this.state.items[i]
			}
		}

		return false
	}

	setAddress = (addressType, address) => {
		this.api.put('basket/address', {
			body: JSON.stringify
		})
	}

	getPaymentIntent = () => {
		return this.state.paymentIntentId
	}

	componentDidMount() {
		this.refresh();
	}

	render() {
		const {children} = this.props
		const {items, subtotal, total, itemCount, totalFaceValue} = this.state

		return (
			<BasketContext.Provider value={{
				items,
				subtotal,
				total,
				itemCount,
				totalFaceValue,
				refresh: this.refresh,
				update: this.update,
				hasItem: this.hasItem,
				updateItem: this.updateItem,
				removeItem: this.removeItem,
				getPaymentIntent: this.getPaymentIntent
			}}>
				{children}
			</BasketContext.Provider>
		)
	}
}

export default BasketContext;
