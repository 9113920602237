
// Libraries
import * as React from 'react'
import { createContext } from 'preact'

export const NotificationContext = createContext({
	notification: null,
})

export class NotificationContextProvider extends React.Component {
	state = {
		notification: null,
	}

	addNotification = (message, type) => {
		const { notification } = this.state

		if(notification) return

		this.setState({
			notification: {
				message,
				type,
				open: true,
			}
		})

		this.beginTimedExpiry()
	}

	beginTimedExpiry = () => {
		setTimeout(() => {
			const { notification } = this.state

			if(!notification) return

			notification.closing = true

			this.setState({notification})
		}, 4600)

		setTimeout(() => {
			this.setState({notification: null})
		}, 5000)
	}

	expireNow = () => {
		const { notification } = this.state

		if(!notification) return

		notification.closing = true

		this.setState({notification})

		setTimeout(() => {
			this.setState({notification: null})
		}, 400)
	}

	render() {
		const { children } = this.props,
		{ notification } = this.state

		return (
			<NotificationContext.Provider value={{
				notification,
				addNotification: this.addNotification,
				expireNow: this.expireNow,
			}}>
				{children}
			</NotificationContext.Provider>
		)
	}
}

export default NotificationContext
